@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Lato-Regular';
    src: url("../assets/font/Lato-Regular.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-Semibold';
    src:  url("../assets/font/Lato-Semibold.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-Bold';
    src:  url("../assets/font/Lato-Bold.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-Black';
    src:  url("../assets/font/Lato-Black.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-Light';
    src: url("../assets/font/Lato-Light.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-LightItalic';
    src: url("../assets/font/Lato-LightItalic.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-Medium';
    src: url("../assets/font/Lato-Medium.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-Italic';
    src: url("../assets/font/Lato-Italic.ttf") format('truetype');
  }
@font-face {
    font-family: 'Lato-Hairline';
    src: url("../assets/font/Lato-Hairline.ttf") format('truetype');
  }